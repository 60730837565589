<template>
  <vca-card>
    <vca-card class="text-center shadowed">
      <h1>{{ $t("logout.redirect") }}</h1>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Logout",
  computed: {
    ...mapGetters({
      callback: "callback",
    }),
  },
  created() {
    this.$store
      .dispatch({ type: "login/logout" })
      .then(() => {
        this.$store.commit("user/current", null);
        /* TODO change to this.callback when callback encoded */
        window.location.href = window.atob(this.callback ? this.callback : "");
      })
      .catch(() => {
        /* TODO change to this.callback when callback encoded */
        window.location.href = window.atob(this.callback ? this.callback : "");
      });
  },
};
</script>

<style lang="scss">
.redirect-info {
  text-align: center;
}
</style>
